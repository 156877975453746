@import "./variables.scss";

@media (max-width: 601px) {
  .sectionTitle {
    margin: 0 auto;
    color: $h1;
    font-size: 22px;
    text-align: center;
    padding: 15px;
    font-style: normal;
    font-family: sans-serif;
  }

  /* Header Section */

  .headerBar img {
    width: 90px;
    height: auto;
    margin-top: 5px;
  }

  .headerBar .button {
    width: 110px;
    margin-top: 10px;
    font-size: 12px;
    float: right;
  }

  /* Menu Icon */
  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }

  .footer {
    background: #f9f9f9;
    height: 100px;
    clip-path: ellipse(125% 100% at 98.45% 0%);
  }

  .footer img {
    margin-top: 25px;
    float: left;
    width: 70px;
    padding-left: 10px;
  }

  .siteLogo {
    color: $white;
    margin-top: -27px;
    font-size: 11px;
    padding-left: 5px;
  }

  .appWrapper p {
    font-size: 14px;
    margin: 0 auto;
    width: 90%;
    line-height: normal;
    font-family: sans-serif;
  }

  .appWrapper h3 {
    font-size: 24px;
  }

  .MuiButton-root {
    background: $button;
    color: $white;
    font-size: 9px;
    width: 135px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    transform: translatez(0);
  }

  /* Nav Bar */

  .mobileNavLinks .MuiListItemText-root {
      text-align: center;

    }
  .mobileNavLinks a {
      text-decoration: none;
      color: $white;
      font-size: 16px;
  }
  .mobileNavLinks a:link{
      text-decoration: none;
      color: $white;
      font-size: 16px;
  }

  .mobileNavLinks a:hover {
      color: #ffffff;
  }

  .desktopNav {
    display: none;
  }

  .mobileNavMenu {
    display: block;
  }

  .mobileNav .logo {
    width: 100px;
  }

  .navLogo {
    width: 75px;
    height: 30px;
  }

  /* Page Content */

    .headerBar .button {
      width: 110px;
      margin-top: 6px;
      font-size: 12px;
      float: right;
    }



    .footer {
        background: #f9f9f9;
        height: 100px;
        clip-path: ellipse(125% 100% at 98.45% 0%);
      }
      
      .footer img {
        margin-top: 25px;
        float: left;
        width: 70px;
        padding-left: 10px;
      }

      .siteLogo {
        color: $white;
        margin-top:-27px;
        font-size: 11px;
        padding-left: 5px;
      }

    .appWrapper p {
        font-size: 14px;
        margin: 0 auto;
        width: 90%;
        line-height: normal;
        font-family: sans-serif;
    }

    .appWrapper h3{
        font-size: 24px;
    }

    .MuiButton-root{
      background: $button;
      color: $white;
      font-size: 9px;
      width: 135px;
      text-align: center;
      display: block;
      margin: 0 auto;
      margin-bottom: 0;
      overflow: hidden;
      position: relative;
      transform: translatez(0);
  }

  /* Nav Bar */ 
    .desktopNav {
        display: none;
    }

    .mobileNavMenu {
        display: block;
    }

    .mobileNav .logo {
      width: 100px;
    }

    .navLogo {
        width: 75px;
        height: 30px;
    }

    /* Page Content */ 


/* About AI Home Section */ 
    .aboutAIContainer {
        background-image: linear-gradient(to right, #ffffff, #e0eff9);
        width: 100%;
        height: 100%;
        padding-bottom: 25px;
      }

      .aboutAIContainer img {
        margin: -20px auto;
        width: 100%;
        
      }

    .aboutAIContainer .aboutTitle {
        color: $aboutAiTitleColor;
        margin: 20px auto;
        font-size: 17px;
        text-align: center;
        background: transparent;
        padding: 10px;
        border-radius: 5px;
        width: 60%;
    }

    .aboutAIContainer .aboutSubTitle {
     display: none;
    }


    #gear1, #gear2 {
    display: none;
  }

  /* Approach Home Section */
  .approachAIContainer {
    background: #dbedf9;
    height: 100%;
    font-size: 18px;
  }

  .approachAIContainer h3 {
    text-align: center;
    padding-left: 30px;
    font-size: 26px;
  }

  .approachAIContainer h3,
  h5 {
    color: $primary;
  }

  .approachAIContainer h5 {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .approachAIContainer .p {
    font-size: 20px;
    text-align: left;
    word-wrap: break-word;
  }

  .approachAIContainer .rightSection {
    width: 95%;
    padding: 15px;
  }

  .approachAIContainer .leftSection {
    margin-top: 40px;
    padding: 15px;
  }

  .productQuadrantsContainer {
    padding-top: 10px;
    background: #f6fcff;
    margin: 0 auto;
    width: 95%;
    height: 100%;
    display: flex;
  }

  .productQuadrantsIconContainer {
    display: none;
  }

  .productQuadrantsContainer p {
    font-size: 18px;
    width: 100%;
  }

  .productQuadrantsContainer h5 {
    font-size: 20px;
  }

  .productQuadrantsContainer img {
    text-align: center;
    width: 25%;
  }

  .videoDemoCarouselContainer video {
    width: 85%;
    height: auto;
  }

  .videoDemoCarouselContainer {
    height: auto;
    width: 100%;
    padding-top: 30px;
    margin-top: 50px;
  }

  .videoCarousel {
    padding-top: 50px;
    padding-bottom: 20px;
    background: $primary;
  }

  .videoDemoCarouselContainer h3 {
    font-size: 40px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    border-radius: 7px;
  }

  .videoDemoCarouselContainer h5 {
    color: $white;
    margin-top: 50px;
    font-size: 16px;
  }

  /* FAQ Section */

  .faqContentContainer {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: 25px auto;
    word-wrap: break-word;
  }

  .faqContentContainer .accordionContainer {
    background-image: linear-gradient(to bottom, #f3f8fc, #e1f1fc);
    padding: 25px;
    border-radius: 10px;
  }

  .faqContentContainer .category {
    font-size: 24px;
    color: #a4bfd0;
    text-align: center;
    padding: 20px;
  }

  .faqContentContainer .titleLine {
    width: 90%;
    height: 2px;
    background-image: linear-gradient(to left, #f8fcff, #e1f1fc);
  }

  .faqContentContainer h5 {
    font-size: 16px;
  }

  .faqContentContainer p {
    margin: 0 auto;
    font-size: 16px;
    width: 100%;
    color: #333;
    text-align: left;
  }

  /* About Us Section */
  .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: 0 auto;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: 22px;
    background-image: linear-gradient(to left, #dbebf6, #fdfeff);
    color: $primary;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: 18px;
    color: #333;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }

  /* Demos Video Section */

  .demoContainer .videoThumbnailContainer {
    height: 800px;
    background: $cardColor;
    border-radius: 10px;
    overflow: auto;
    text-align: left;
    margin: 0;
  }

  .demoContainer .videoThumbnailContainer .Link:hover {
    background: $link;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .demoContainer .videoThumbnailContainer .listMediaTitle:hover {
    color: $white;
  }

  .demoContainer .contentContainer {
    background-image: linear-gradient(to bottom, #f1f9ff, #f6fcff);
    height: 100%;
  }

  .demoContainer h6 {
    font-size: 12px;
  }

  .demoContainer small {
    display: none;
  }

  /* About Us Section */

  .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  .aboutUsPageContainer .aboutUsInfoSection {
    margin: 0 auto;
  }

  .aboutUsPageContainer .aboutUsInfoSection .title {
    font-size: 22px;
    background-image: linear-gradient(to left, #dbebf6, #fdfeff);
    color: $primary;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description {
    font-size: 18px;
    color: #333;
    padding-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }

  .aboutUsPageContainer .profileContainer {
    margin: 25px auto;
  }

  .profileContainer .profileInfo {
    margin: 50px auto;
  }

  .profileContainer .profileInfo .name {
    font-size: 18px;
    color: $primary2;
    font-weight: bold;
  }

  .profileContainer .profileInfo .title {
    font-size: 14px;
    color: $primary;
  }

  .profileContainer .profileInfo .email {
    font-size: 13px;
    color: #7a99b0;
  }

  /* OFFERINGS Page Section */

  .offeringsContentContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 25px auto;
  }

  .offeringsContentContainer a {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:link {
    text-decoration: none;
    color: $primary;
    font-size: 24px;
  }

  .offeringsContentContainer a:hover {
    text-decoration: none;
    color: #74add5;
    font-size: 24px;
  }

  .offeringsContentContainer h5 {
    color: $primary2;
  }

  .offeringButton {
    background: transparent;
    color: #609cc6;
    border-radius: 100px;
    width: 100%;
    float: left;
    height: 50px;
    font-size: 12px;
  }

    /* Contact Us Container */
    .contactPageContainer {
      height: 100%;
    }
    .contactUsContentContainer {
     height: 100%;
    }
  
    .contactUsContentContainer h4 {
      color: $primary;
      font-size: 22px;
  
    }

  /* Video Carousel Home Page Section */

  .videoDemoCarouselContainer {
    height: auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 30px;
  }

  .videoDemoCarouselContainer .sectionTitle {
    text-align: left;
  }

  .videoDemoCarouselContainer .sectionDescription {
    font-size: $desktopFontSize;
    margin: 0 auto;
    text-align: left;
  }

  .videoDemoCarouselContainer .sectionDescription .title {
    margin-left: 20px;
  }

  .videoDemoCarouselContainer .sectionDescription .text {
    text-align: left;
    font-size: 15px;
    width: 90%;
    margin: 0 auto;
  }

  .videoDemoCarouselContainer .button {
    margin-top: 15px;
    border-radius: 50px;
    height: 60px;
    width: 150px;
    padding: 15px;
    background-color: transparent;
    color: $primary;
    border: 2px solid $primary;
    font-size: 14px;
  }

  .videoDemoCarouselContainer video {
    width: 90%;
    height: auto;
    box-shadow: 0 10px 10px 0 #03365b;
  }

  .videoCarousel {
    padding-top: 50px;
    padding-bottom: 20px;
    background: $secondary;
  }

  .videoDemoCarouselContainer h3 {
    font-size: 40px;
    margin-top: 50px;
  }

  .videoDemoCarouselContainer video {
    border-radius: 7px;
  }

  .videoDemoCarouselContainer h5 {
    color: $primary;
    font-size: 22px;
  }

  /* Layer Cake */

  .layerCakeContent {
    border-radius: 10px;
    width: 100%;
    font-size: 8px;
    margin: 0 auto;
  }
  .layerCakeContentSideA,
  .layerCakeContentSideB {
    margin: 125px auto;
    font-family: sans-serif;
  }

  .layerCakeContentSideA {
    border-radius: 10px 0 0 10px;
    background-image: linear-gradient(to bottom, #ffffff, #d8eefc);
    height: 50vh;
  }

  .layerCakeContentSideB {
    border-radius: 0 10px 10px 0;
    background-image: radial-gradient(#f2f2f2 1.5px, transparent 0.5px);
    background-size: 10px 10px;
    background-color: #ffffff;
    margin-top: -110px;
    height: 80vh;
  }

  .layerCakeLayerTitle {
    margin-top: 25px;
    padding-left: 50px;
    text-align: left;
    color: $white;
  }

  .layerCakeContentSideA .desc {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    color: $white;
    font-size: 14px;
  }

  .layerCakeContentSideB .header {
    text-align: left;
    font-weight: 600;
    width: 90%;
    margin: 25px auto;
  }

  .layerLogo {
    width: 150px;
  }

  /* Component */
  .Card {
    text-align: center;
    background: transparent;
  }

  .Card .cardText {
    font-size: 18px;
    width: 75%;
    word-wrap: break-word;
    text-align: left;
    color: $body1;
    margin: 0 auto;
  }

  .Card h5 {
    color: $primary;
    font-size: 16px;
    width: 85%;
    height: 100%;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;
    color: #ffffff;
    word-wrap: break-word;
  }

   /* Home Buttons sectiion - Landing Page */ 
   .homeButtons {
    margin: -10px auto;
    width: 90%;
    height: 150px;

  }

  .homeButtons .MuiButton-root:hover {
    background: #f8f8f8;
    color: $primary;
  
   }

  .homeButtons .MuiButton-root {
    height: 85px;
    font-size: 10px;
    padding-top: 18px;
    width: 100%;
  }

  .homeButtons .MuiButtonGroup-root {
    margin-top:60px;
    width: 100%;
  }

  /* Profile Card Section */
  .ProfileCard {
    width: 100%;
    text-align: center;
  }

  .ProfileCard img {
    text-align: center;
    margin: 25px auto;
    height: 200px;
    border-radius: 10px;
    box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);
    transform: rotate(2deg);
  }

  .badgeComponentContainer {
    padding-top: 20px;
    text-align: left;
    margin: 0;
  }


}
