@import './variables.scss';

@media (max-width: 1650px) {
  .desktopNav {
    display: none;
  }

  .mobileNavMenu {
    display: block;
  }
  .mobileNav {
    text-align: center;
    background: $primary;
  }

  .mobileNav .logo {
    width: 100px;
  }


  .mobileNavLinks .MuiListItemText-root {
    text-align: center;

}



  .mobileNavLinks a {
      text-decoration: none;
      color: $white;
      font-size: 16px;
  }
  .mobileNavLinks a:link{
      text-decoration: none;
      color: $white;
      font-size: 16px;
  }

  .mobileNavLinks a:hover {
      color: #ffffff;
  }

  .Card {
    padding-bottom: 50px;
  }
      
  .Card h5 {
    
    width: 95%;
    height: 60px;
    font-size: 14px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 50px;
    border-radius: 10px 10px 0 0;
    color: $white;
    word-wrap: break-word;
    font-weight: 600;
   
  }

  .aboutAIContainer .aboutTitle {
    color: $primary;
    margin: 55px auto;
    font-size: 25px;
    position: absolute;
    background: transparent;
    padding: 30px 0 200px 25px;
    border-radius: 5px;
    text-align: left;
    width: 50%;
}

/* Home Buttons sectiion - Landing Page */ 
.homeButtons {
  margin: -10px auto;
  width: 100%;
  height: 150px;

}

.homeButtons .MuiButton-root:hover {
  background: #f8f8f8;
  color: $primary;
  border: 5px solid #f8f8f8;
  border-radius: 8px;
 }

 .homeButtons .MuiButton-root {
   height: 65px;
   font-size: 12px;
    border: 5px solid #fff;
   border-radius: 8px;
 }

 .homeButtons .MuiButtonGroup-root {
   margin-top:60px;
   width: 60%;
   padding: 15px;
 }


.offeringButton {
  background: transparent;
  color: #609cc6;
  border-radius: 100px;
  width:30%;
  float: left;
  height: 65px;
  font-size: 12px

}

}


@media (max-width: 1000px) {

    .sectionTitle {
        margin: 0 auto;
        color: $h1;
        font-size: 24px;
        text-align: center;
        padding: 15px;
        font-style: normal;
        font-family: sans-serif;
    }

    
    /* Header Bar Section */
    .headerBar img {
        width: 80px;
        height: auto;
        margin-top: 10px;
    }

    .headerBar .button {
      width: 130px;
      font-size: 14px;
      height: auto;
      margin-top: 10px;
      float: right;
   }

   /* Footer Section */ 
    .footer {
        background: #f9f9f9;
        height: 100px;
        clip-path: ellipse(125% 100% at 98.45% 0%);
      }
      
      .footer img {
        margin-top: 25px;
        float: left;
        width: 80px;
        padding-left: 10px;
      }

      .siteLogo {
        color: $white;
        margin-top:-27px;
        font-size: 18px;
        padding-left: 5px;
      }

    .appWrapper p {
        font-size: 14px;
        margin: 0 auto;
        width: 90%;
        line-height: normal;
        font-family: sans-serif;
    }

    .appWrapper h3{
        font-size: 24px;
    }

    .MuiButton-root{
      background: $button;
      color: $white;
      font-size: 12px;
      width: 150px;
      text-align: center;
      display: block;
      margin: 0 auto;
      margin-bottom: 0;
      overflow: hidden;
      position: relative;
      transform: translatez(0);
  }

  /* Menu Icon */
  .menuHamIcon {
    margin-top: -10px;
    text-align: center;
  }



  /* Nav Bar */ 
  
     .navBar {
         margin-top: 10px;
     }

    .navLogo {
        width: 105px;
        height: 40px;
    }

   

    /* Page Content */ 

    .aboutAIContainer {
        background-image: linear-gradient(to right, #ffffff, #e0eff9);
        width: 100%;
        height: 100%;
        padding-bottom: 25px;
      }

    .aboutAIContainer .aboutTitle {
        color: $primary;
        margin: 5px auto;
        font-size: 18px;
        position: absolute;
        background: transparent;
        padding: 30px 0 200px 25px;
        border-radius: 5px;
        text-align: left;
        width: 50%;
        padding-bottom: 20px;
    }

    .aboutAIContainer img {
      margin: -20px auto;
      width: 100%;
      
    }

    .aboutAIContainer .aboutSubTitle {
      font-size: 12px;
      text-align: $aboutSubTitleTextAlign;
      color: $white;
    }

    .aboutAIContainer .aboutTitle .line {
      background: $aboutAiLineBackground;
      color: $aboutAiLineColor;
      display: $aboutAiLineDisplay;
      padding: $aboutAiLinePadding;
      text-decoration: $aboutAiLineTextDecoration;
      transition: $aboutAiLineTransition;
    }

    .aboutAIContainer .banner:hover {
      color: $aboutAiBannerHoverColor;
       --underline-width: 100%;
     }


    .approachAIContainer {
        background-image: linear-gradient(to bottom, #f1f9ff, #b7d6ea);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        word-wrap: break-word;
    }

    .approachAITitle {
    
        margin-top: -40px;
    }
      
    .approachAIContainer h3,h5{
        color: $primary;
    }

    .approachAIContainer h5
    {
        font-size: 20px;
        padding-bottom: 15px;
    }

    .approachAIContainer h3{
        text-align: left;
        padding-left: 30px;
        font-size: 20px;
    }

    .approachAIContainer .rightSection {
        width: 95%;
        float: right;
        padding: 15px;
    }

    .approachAIContainer .leftSection {
        float: left;
        padding: 15px;
        margin-top: 100px;
    }

    .productQuadrantsContainer {
        padding-top: 10px;
        background: #f6fcff;
        margin: 0 auto;
        width: 95%;
        height: 100%;
        display: flex;
        font-size: 16px;
        word-wrap: break-word;
    }

    .productQuadrantsContainer h5 {
        font-size: 18px;
    }
    
    .productQuadrantsContainer img {
        text-align: center;
        width: 50%;
    }

    /* About Us Section */ 
    
    .aboutUsPageContainer {
      flex-grow: 1;
      width: 90%;
      height: 100%;
      margin: 0 auto;
      
    }

    .aboutUsPageContainer .pageTitle {
      color: $primary2;
      font-weight: bold;
      font-size: 22px;
    }

    .aboutUsPageContainer .aboutUsInfoSection{
      margin: 0 auto;
     
    }

    .aboutUsPageContainer .aboutUsInfoSection .title{
      font-size: 22px;
      background-image:  linear-gradient(to left, #dbebf6, #fdfeff);
      color: $primary;
      padding: 15px;
    }

    .aboutUsPageContainer .aboutUsInfoSection .description{
      font-size: 18px;
      color: #333;
      padding-bottom: 50px;
      padding-left: 25px;
      padding-right: 25px;
      height: auto;
    }

    .aboutUsPageContainer .profileContainer {
      margin: 25px auto;

    }

    .profileContainer  .profileInfo {
      margin: 50px auto;

    }

    .profileContainer  .profileInfo .name{
      font-size: 20px;
      color: $primary2;
      font-weight: bold;
     }

     .profileContainer  .profileInfo .title{
      font-size: 18px;
      color: $primary;
     }

     .profileContainer  .profileInfo .email{
      font-size: 15px;
      color: #7a99b0;
     }
    

     /* Video Demo */ 

    .videoDemoCarouselContainer {
        height: auto;
        width: 100%;
        padding-top: 30px;
        margin-top: 50px;
      }

      .videoDemoCarouselContainer video {
            width: 85%;
            height: auto;
        }

      .videoCarousel {
          padding-top: 50px;
          padding-bottom: 20px;
         background: $primary;
      }

      .videoDemoCarouselContainer h3 {
        font-size: 40px;
        margin-top: 50px;
      }
      
      
      .videoDemoCarouselContainer video {
        border-radius: 7px;
      }
      
      .videoDemoCarouselContainer h5 {
        color: $white;
        margin-top: 50px;
        font-size: 20px;
      }


    /* FAQ Section */ 
    .faqContentContainer {
        flex-grow: 1;
        width: 100%;
        height: auto;
        margin: 25px auto;
        word-wrap: break-word;
      }

      .faqContentContainer  .accordionContainer{
        background-image: linear-gradient(to bottom, #f3f8fc, #e1f1fc);
        padding: 25px;
        border-radius: 10px;
      } 
  
      .faqContentContainer .category {
        font-size: 24px;
        color: #a4bfd0;
        text-align: left;
        padding: 20px;
        padding-left: 100px;
      }

      .faqContentContainer .titleLine {
        width: 90%;
        height: 2px;
        background-image: linear-gradient(to left, #f8fcff, #e1f1fc);
      }

    .faqContentContainer h5 {
          font-size: 20px;
    }
      
    .faqContentContainer p {
        margin: 0 auto;
        font-size: 18px;
        width: 100%;
        color: #333;
        text-align: center;
    }


   
   /* About Us Section */ 
   .aboutUsPageContainer {
    flex-grow: 1;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    
  }

  .aboutUsPageContainer .pageTitle {
    color: $primary2;
    font-weight: bold;
    font-size: 22px;
  }

  .aboutUsPageContainer .aboutUsInfoSection{
    margin: 0 auto;
   
  }

  .aboutUsPageContainer .aboutUsInfoSection .title{
    font-size: 18px;
    background-image:  linear-gradient(to left, #dbebf6, #fdfeff);
    color: $primary;
    padding: 15px;
  }

  .aboutUsPageContainer .aboutUsInfoSection .description{
    font-size: 18px;
    color: #333;
    padding-left: 25px;
    padding-right: 25px;
    height: 175px;
  }


  /* Demo Videos Section */ 
      .demoContainer .videoThumbnailContainer {
        height: 800px;
        background: $cardColor;
        border-radius: 10px;
        overflow: auto;
        text-align: left;
        margin: 0;
      }
      
      .demoContainer .videoThumbnailContainer .Link:hover {
        background: $link;
        animation: fadeInAnimation ease 1s; 
        animation-iteration-count: 1; 
        animation-fill-mode: forwards; 
      }
      
      .demoContainer .contentContainer {
        background-image: linear-gradient(to bottom, #f1f9ff, #f6fcff);
        height: 100%;
        
      }

      .demoContainer .videoThumbnailContainer  .listMediaTitle:hover  {
        color: $white;
      }

      .demoContainer  h6 {
        font-size: 14px;
      }

      .demoContainer small {
        color: $h3;
        opacity: 0.7;
        font-size: 12px;
        text-align: left;
      }

      /* Video Carousel Home Page Section */ 

      .videoDemoCarouselContainer {
        height: auto;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 30px;

       
      }

      .videoDemoCarouselContainer .sectionTitle {
        text-align: left;
    
      }

      .videoDemoCarouselContainer .sectionDescription {
        font-size: $desktopFontSize;
        margin: 0 auto;
        text-align: left;
      }

      .videoDemoCarouselContainer .sectionDescription .title {
        margin-left: 40px;
      }

      .videoDemoCarouselContainer .sectionDescription .text {
        text-align: left;
        font-size: $desktopFontSize;
        width: 90%;
        margin: 0 auto;
      }

      .videoDemoCarouselContainer .button {
        margin-top: 15px;
       border-radius: 50px;
       height: 60px;
       padding: 15px;
       background-color: transparent;
       color: $primary;
       border: 2px solid $primary;
       font-size: 14px;
      }


      .videoDemoCarouselContainer video {
            width: 85%;
            height: auto;
            box-shadow: 0 10px 10px 0 #03365b;
        }

      .videoCarousel {
          padding-top: 50px;
          padding-bottom: 20px;
          background: $secondary;
         
      }

  
      .videoDemoCarouselContainer h3 {
        font-size: 40px;
        margin-top: 50px;
      }
      
      
      .videoDemoCarouselContainer video {
        border-radius: 7px;
      }
      
      .videoDemoCarouselContainer h5 {
        color: $primary;
        font-size: 22px;
      }

       /* OFFERINGS Page Section */ 

       .offeringsContentContainer {
        flex-grow: 1;
        width: 90%;
        height: 100%;
        margin: 25px auto;
      }

      .offeringsContentContainer a {
        text-decoration: none;
        color: $primary;
        font-size: 24px;
      }
    
      .offeringsContentContainer a:link {
        text-decoration: none;
        color: $primary;
        font-size: 24px;
      }
    
      .offeringsContentContainer a:hover {
        text-decoration: none;
        color: #74add5;
        font-size: 24px;
      }

      .offeringsContentContainer h5 {
        color: $primary2;
      }

      .offeringButton {
        background: transparent;
        color: #609cc6;
        border-radius: 100px;
        width:50%;
        float: left;
        height: 75px;
        font-size: 12px
    
      }

      /* Layer Cake */ 

      .layerCakeContent{
        border-radius: 10px;
        width: 100%;
        margin: 0 auto;
        
      }
      .layerCakeContentSideA, .layerCakeContentSideB{
        margin: 125px auto;
        font-family: sans-serif;
        height: 75vh;
    
      }

      .layerCakeContentSideA {
        border-radius: 10px 0 0 10px;
        background-image: linear-gradient(to bottom, #ffffff, #d8eefc);
        height: 40vh;
      }

      .layerCakeContentSideB {
        border-radius: 0 10px 10px 0;
        background-image: radial-gradient(#f2f2f2 1.5px, transparent 0.5px);
        background-size: 10px 10px;
        background-color: #ffffff;
        height: 75vh;
        margin-top: -110px;
      }

      .layerCakeLayerTitle  {
        margin-top: 25px;
        padding-left: 50px;
        text-align: left;
        font-size: 15px;
        color: $white;
  
      }

      .layerCakeContentSideA .desc {
        width: 80%;
        margin: 0 auto;
        text-align: left;
        color: $white;
        font-size: 16px;
        
      }

      .layerCakeContentSideB .header{
        text-align: left;
        font-weight: 600;
        width: 90%;
        margin: 25px auto;
      }

    
      .layerLogo{
        width: 175px;
      }



    /* Component */ 

    .Card {
        text-align: center;
        background: transparent;
        
    } 

    .Card .cardText {
      font-size: 16px;
      width: 75%;
      word-wrap: break-word;
      text-align: left;
      color: $body1;
      margin: 0 auto;
  }
    .Card h5 {
        color: $primary;
        font-size: 14px;
        width: 85%;
        height: 95px;
        margin: 0 auto;
        padding: 20px;
        border-radius: 10px 10px 0 0;
        color: #FFFFFF;
        word-wrap: break-word;

    }

      /* Contact Us Container */
      .contactPageContainer {
        height: 100%;
      }
      .contactUsContentContainer {
      height: 100%;
      }

      .contactUsContentContainer h4 {
        color: $primary;
        font-size: 22px;

      }

    /* Home Buttons sectiion - Landing Page */ 
    .homeButtons {
      margin: -10px auto;
      width: 100%;
      height: 150px;

    }

    .homeButtons .MuiButton-root:hover {
      background: #f8f8f8;
      color: $primary;
    
     }
  
    .homeButtons .MuiButton-root {
      height: 85px;
      font-size: 12px;
      padding-top: 18px;
      width: 200px;
    }
  
    .homeButtons .MuiButtonGroup-root {
      margin-top:60px;
      width: 70%;
    }
  

    /* Profile Card Section */ 

    .ProfileCard {
      width: 100%;
      text-align: center;
  }

    .ProfileCard img {
        text-align: center;
        margin: 25px auto;
        height: 200px;
        border-radius: 10px;
        box-shadow: 5px 10px 8px 5px rgb(113, 113, 113);
        transform: rotate(2deg);
      color: #f0f7fb;
    }

    /* Badge */ 

    .badgeComponentContainer {
        float: left;
        margin: 0;
        padding: 20px;
    }



}