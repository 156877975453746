
@import './variables.scss';

/* Fade Animation */

.fadePanel {
  animation: fadeInAnimation ease 2s; 
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 

}

@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
  } 
} 

/* Contact Us Buttons */
.btn {
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  background-color: $primary2;
  padding: 12px 40px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.20);
  width: 300px;
  height: 55px;
  border-radius: 5px;
}

.btn span {
  position: relative; 
  z-index: 1;
}

.btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 490%;
  width: 140%;
  background: #1e91e4;
  color: $primary2;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  transform: translateX(-98%) translateY(-25%) rotate(45deg);
}


.btn:hover:after {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

/* Circle Flasher Effect */

 .circleFlasher{
  border-radius: 100%;
  width: 15px;
  margin-left: 25px;
  height: 15px;
  background: #d2a940;
  animation: 
  circleFlasherPulse 1s ease infinite alternate;

}

@keyframes circleFlasherPulse {
  0%, 100% {
    background-color: #d2a940;
  }

  25% {
    background-color: #eac66a;
  }

  50% {
    background-color: #ffe7a9;
  }

  75% {
    background-color: #fff4d7;
  }

}


/* Square Flasher Effect */

.squareFlasher{
  margin: 100px  0 0 100px;
  filter:
  drop-shadow(.2rem .1rem 1rem #f16e4c);
  width: 25px;
  height: 25px;
  position: absolute;
  background: #d2a940;
  animation: 
  squareFlasherPulse 1s ease infinite alternate;

}

.squareFlasher2{
  margin: 600px  0 0 280px;
  filter:
  drop-shadow(.2rem .2rem 1rem #f16e4c);
  width: 15px;
  height: 15px;
  position: absolute;
  background: #d2a940;
  animation: 
  squareFlasherPulse 1s ease infinite alternate;

}

.squareFlasher3{
  margin: 800px  0 0 100px;
  filter:
  drop-shadow(.2rem .2rem 1rem #f16e4c);
  width: 8px;
  height: 8px;
  position: absolute;
  background: #d2a940;
  animation: 
  squareFlasherPulse 1s ease infinite alternate;

}

@keyframes squareFlasherPulse {
  0%, 100% {
    background-color: #d2a940;
  }

  25% {
    background-color: #eac66a;
  }

  50% {
    background-color: #ffe7a9;
  }

  75% {
    background-color: #fff4d7;
  }

}


/* Button Animation */
.buttonGradient {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: auto;
  margin: auto;
  z-index: -1;
  background: radial-gradient(90px circle at top center, #35a0ed, $button);
  transition: all 0s ease-out 0s;
  transform: translatex(-140px);
  animation: 10s linear 0s infinite move;
}

@keyframes move {
  0% {
    transform: translatex(-140px);
  }
  25% {
    transform: translatex(140px);
    opacity: 0.3;
  }
  50% {
    transform: translatex(140px);
    opacity: 1;
    background: radial-gradient(90px circle at bottom center, $primary, $button);
  }
  75% {
    transform: translatex(-140px);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translatex(-140px);
    background: radial-gradient(90px circle at top center, $primary, $button);
  }
}


/* Spinner Loader Animation */
.loader {
  color: #006bb6;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}











